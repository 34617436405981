import plugin from "tailwindcss/plugin";

export default plugin(({ theme, addComponents }) => {
  addComponents({
    // Default
    ".t-default-xs": {
      fontFamily: theme("fontFamily.sans"),
      fontSize: theme("fontSize.default-xs"),
      lineHeight: "1.4",
    },
    ".t-default-sm": {
      fontFamily: theme("fontFamily.sans"),
      fontSize: theme("fontSize.default-sm"),
      lineHeight: "1.4",
      fontWeight: "400",
    },
    ".t-default-md": {
      fontFamily: theme("fontFamily.sans"),
      fontSize: theme("fontSize.default-md"),
      lineHeight: "1.5",
      fontWeight: "400",
    },

    // Prose
    ".t-prose-xs": {
      fontFamily: theme("fontFamily.serifRegular"),
      fontSize: theme("fontSize.prose-xs"),
      lineHeight: "1.6",
      fontWeight: "400",
    },
    ".t-prose-sm": {
      fontFamily: theme("fontFamily.serifRegular"),
      fontSize: theme("fontSize.prose-sm"),
      lineHeight: "1.6",
      fontWeight: "400",
    },
    ".t-prose-md": {
      fontFamily: theme("fontFamily.serifRegular"),
      fontSize: theme("fontSize.prose-md"),
      lineHeight: "1.6",
      fontWeight: "400",
    },
    ".t-prose-lg": {
      fontFamily: theme("fontFamily.serifRegular"),
      fontSize: theme("fontSize.prose-lg"),
      lineHeight: "1.6",
      fontWeight: "400",
    },
    ".t-prose-xl": {
      fontFamily: theme("fontFamily.serifRegular"),
      fontSize: theme("fontSize.prose-xl"),
      lineHeight: "1.6",
      fontWeight: "400",
    },
    ".t-prose-2xl": {
      fontFamily: theme("fontFamily.serifRegular"),
      fontSize: theme("fontSize.prose-2xl"),
      lineHeight: "1.6",
      fontWeight: "400",
    },
    ".t-prose-3xl": {
      fontFamily: theme("fontFamily.serifRegular"),
      fontSize: theme("fontSize.prose-3xl"),
      lineHeight: "1.6",
      fontWeight: "400",
    },
    ".t-prose-4xl": {
      fontFamily: theme("fontFamily.serifRegular"),
      fontSize: theme("fontSize.prose-4xl"),
      lineHeight: "1.6",
      fontWeight: "400",
    },
    ".t-prose-5xl": {
      fontFamily: theme("fontFamily.serifRegular"),
      fontSize: theme("fontSize.prose-5xl"),
      lineHeight: "1.6",
      fontWeight: "400",
    },

    // Prose italic
    // TODO: use theme values instead of duplicating.
    ".t-prosi-xs": {
      fontFamily: theme("fontFamily.serifItalic"),
      fontSize: "clamp(0.7813rem, 0.7761rem + 0.0259vw, 0.8rem)",
      lineHeight: "1.6",
      fontWeight: "400",
      fontStyle: "italic",
    },
    ".t-prosi-sm": {
      fontFamily: theme("fontFamily.serifItalic"),
      fontSize: "clamp(0.9375rem, 0.9203rem + 0.0862vw, 1rem)",
      lineHeight: "1.6",
      fontWeight: "400",
      fontStyle: "italic",
    },
    ".t-prosi-md": {
      fontFamily: theme("fontFamily.serifItalic"),
      fontSize: "clamp(1.125rem, 1.0905rem + 0.1724vw, 1.25rem)",
      lineHeight: "1.6",
      fontWeight: "400",
      fontStyle: "italic",
    },
    ".t-prosi-lg": {
      fontFamily: theme("fontFamily.serifItalic"),
      fontSize: "clamp(1.35rem, 1.2914rem + 0.2931vw, 1.5625rem)",
      lineHeight: "1.6",
      fontWeight: "400",
      fontStyle: "italic",
    },
    ".t-prosi-xl": {
      fontFamily: theme("fontFamily.serifItalic"),
      fontSize: "clamp(1.62rem, 1.5281rem + 0.4595vw, 1.9531rem)",
      lineHeight: "1.2",
      fontWeight: "400",
      fontStyle: "italic",
    },
    ".t-prosi-2xl": {
      fontFamily: theme("fontFamily.serifItalic"),
      fontSize: "clamp(1.944rem, 1.8068rem + 0.6861vw, 2.4414rem)",
      lineHeight: "1.2",
      fontWeight: "400",
      fontStyle: "italic",
    },
    ".t-prosi-3xl": {
      fontFamily: theme("fontFamily.serifItalic"),
      fontSize: "clamp(2.3328rem, 2.1345rem + 0.9917vw, 3.0518rem)",
      lineHeight: "1.2",
      fontWeight: "400",
      fontStyle: "italic",
    },
    ".t-prosi-4xl": {
      fontFamily: theme("fontFamily.serifItalic"),
      fontSize: "clamp(2.7994rem, 2.5193rem + 1.4005vw, 3.8147rem)",
      lineHeight: "1.2",
      fontWeight: "400",
      fontStyle: "italic",
    },
    ".t-prosi-5xl": {
      fontFamily: theme("fontFamily.serifItalic"),
      fontSize: "clamp(3.3592rem, 2.9705rem + 1.9436vw, 4.7684rem)",
      lineHeight: "1.2",
      fontWeight: "400",
      fontStyle: "italic",
    },

    // Strong
    // TODO: use theme values instead of duplicating.
    ".t-strong-xs": {
      fontFamily: theme("fontFamily.sans"),
      fontSize: "clamp(0.7813rem, 0.7761rem + 0.0259vw, 0.8rem)",
      lineHeight: "1.2",
      fontWeight: "500",
    },
    ".t-strong-sm": {
      fontFamily: theme("fontFamily.sans"),
      fontSize: "clamp(0.9375rem, 0.9203rem + 0.0862vw, 1rem)",
      lineHeight: "1.2",
      fontWeight: "500",
    },
    ".t-strong-md": {
      fontFamily: theme("fontFamily.sans"),
      fontSize: "clamp(1.125rem, 1.0905rem + 0.1724vw, 1.25rem)",
      lineHeight: "1.2",
      fontWeight: "500",
    },
    ".t-strong-lg": {
      fontFamily: theme("fontFamily.sans"),
      fontSize: "clamp(1.35rem, 1.2914rem + 0.2931vw, 1.5625rem)",
      lineHeight: "1.5",
      fontWeight: "350",
    },
    ".t-strong-lg-xl": {
      fontFamily: theme("fontFamily.sans"),
      fontSize: theme("fontSize.strong-lg-xl"),
      lineHeight: "1.6",
      fontWeight: "350",
    },
    ".t-strong-xl": {
      fontFamily: theme("fontFamily.sans"),
      fontSize: "clamp(1.62rem, 1.5281rem + 0.4595vw, 1.9531rem)",
      lineHeight: "1.6",
      fontWeight: "350",
      letterSpacing: "0.01em",
    },
    ".t-strong-2xl": {
      fontFamily: theme("fontFamily.sans"),
      fontSize: "clamp(1.944rem, 1.8068rem + 0.6861vw, 2.4414rem)",
      lineHeight: "1.2",
      fontWeight: "350",
      letterSpacing: "0.01em",
    },
    ".t-strong-3xl": {
      fontFamily: theme("fontFamily.sans"),
      fontSize: "clamp(2.3328rem, 2.1345rem + 0.9917vw, 3.0518rem)",
      lineHeight: "1.2",
      fontWeight: "350",
      letterSpacing: "0.01em",
    },
    ".t-strong-4xl": {
      fontFamily: theme("fontFamily.sans"),
      fontSize: "clamp(2.7994rem, 2.5193rem + 1.4005vw, 3.8147rem)",
      lineHeight: "1.2",
      fontWeight: "325",
      letterSpacing: "0.0075em",
    },
    ".t-strong-4xl-5xl": {
      fontFamily: theme("fontFamily.sans"),
      fontSize: theme("fontSize.strong-4xl-5xl"),
      lineHeight: "1.2",
      fontWeight: "300",
      letterSpacing: "0.005em",
    },
    ".t-strong-5xl": {
      fontFamily: theme("fontFamily.sans"),
      fontSize: theme("fontSize.strong-5xl"),
      lineHeight: "1.2",
      fontWeight: "300",
      letterSpacing: "0.005em",
    },
    ".t-strong-6xl": {
      fontFamily: theme("fontFamily.sans"),
      fontSize: "clamp(3.3593rem, 2.5618rem + 3.9872vw, 6.25rem)",
      lineHeight: "1.2",
      fontWeight: "350",
      letterSpacing: "0.01em",
    },
  });
});
