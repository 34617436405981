import circleIconPlugin from "./src/lib/tw/plugins/circle-icon";
import screenSafePlugin from "./src/lib/tw/plugins/screen-safe";
import scrollbarHiddenPlugin from "./src/lib/tw/plugins/scrollbar-hidden";
import sectionPlugin from "./src/lib/tw/plugins/section";
import typographyPlugin from "./src/lib/tw/plugins/typography";

/** @type {import("tailwindcss").Config} */
module.exports = {
  content: ["./src/**/*.{js,ts,jsx,tsx,mdx}"],
  theme: {
    fontFamily: {
      sans: [
        "var(--font-sans)",
        "ui-sans-serif",
        "system-ui",
        "-apple-system",
        "BlinkMacSystemFont",
        "Segoe UI",
        "Roboto",
        "Helvetica Neue",
        "Arial",
      ],
      serifRegular: [
        "var(--font-serif-regular)",
        "ui-serif",
        "Georgia",
        "serif",
      ],
      serifItalic: ["var(--font-serif-italic)", "ui-serif", "Georgia", "serif"],
    },
    fontSize: {
      // Default
      "default-xs": "clamp(0.7813rem, 0.7761rem + 0.0259vw, 0.8rem)",
      "default-sm": "clamp(0.9375rem, 0.9203rem + 0.0862vw, 1rem)",
      "default-md": "clamp(1.125rem, 1.0905rem + 0.1724vw, 1.25rem)",

      // Prose
      "prose-xs": "clamp(0.7813rem, 0.7761rem + 0.0259vw, 0.8rem)",
      "prose-sm": "clamp(0.9375rem, 0.9203rem + 0.0862vw, 1rem)",
      "prose-md": "clamp(1.125rem, 1.0905rem + 0.1724vw, 1.25rem)",
      "prose-lg": "clamp(1.35rem, 1.2914rem + 0.2931vw, 1.5625rem)",
      "prose-xl": "clamp(1.62rem, 1.5281rem + 0.4595vw, 1.9531rem)",
      "prose-2xl": "clamp(1.944rem, 1.8068rem + 0.6861vw, 2.4414rem)",
      "prose-3xl": "clamp(2.3328rem, 2.1345rem + 0.9917vw, 3.0518rem)",
      "prose-4xl": "clamp(2.7994rem, 2.5193rem + 1.4005vw, 3.8147rem)",
      "prose-5xl": "clamp(3.3592rem, 2.9705rem + 1.9436vw, 4.7684rem)",

      // Prose italic
      "prosi-xs": "clamp(0.7813rem, 0.7761rem + 0.0259vw, 0.8rem)",
      "prosi-sm": "clamp(0.9375rem, 0.9203rem + 0.0862vw, 1rem)",
      "prosi-md": "clamp(1.125rem, 1.0905rem + 0.1724vw, 1.25rem)",
      "prosi-lg": "clamp(1.35rem, 1.2914rem + 0.2931vw, 1.5625rem)",
      "prosi-xl": "clamp(1.62rem, 1.5281rem + 0.4595vw, 1.9531rem)",
      "prosi-2xl": "clamp(1.944rem, 1.8068rem + 0.6861vw, 2.4414rem)",
      "prosi-3xl": "clamp(2.3328rem, 2.1345rem + 0.9917vw, 3.0518rem)",
      "prosi-4xl": "clamp(2.7994rem, 2.5193rem + 1.4005vw, 3.8147rem)",
      "prosi-5xl": "clamp(3.3592rem, 2.9705rem + 1.9436vw, 4.7684rem)",

      // Strong
      "strong-xs": "clamp(0.7813rem, 0.7761rem + 0.0259vw, 0.8rem)",
      "strong-sm": "clamp(0.9375rem, 0.9203rem + 0.0862vw, 1rem)",
      "strong-md": "clamp(1.125rem, 1.0905rem + 0.1724vw, 1.25rem)",
      "strong-lg": "clamp(1.35rem, 1.2914rem + 0.2931vw, 1.5625rem)",
      "strong-lg-xl": "clamp(1.35rem, 1.2466rem + 0.8145vw, 1.9531rem)",
      "strong-xl": "clamp(1.62rem, 1.5281rem + 0.4595vw, 1.9531rem)",
      "strong-2xl": "clamp(1.944rem, 1.8068rem + 0.6861vw, 2.4414rem)",
      "strong-3xl": "clamp(2.3328rem, 2.1345rem + 0.9917vw, 3.0518rem)",
      "strong-4xl": "clamp(2.7994rem, 2.5193rem + 1.4005vw, 3.8147rem)",
      "strong-4xl-5xl": "clamp(2.8125rem, 2.155rem + 2.8054vw, 4.75rem)",
      "strong-5xl": "clamp(3.3592rem, 2.9705rem + 1.9436vw, 4.7684rem)",
      "strong-6xl": "clamp(3.3593rem, 2.5618rem + 3.9872vw, 6.25rem)",
    },
    borderRadius: {
      sm: "clamp(0.875rem, 0.8578rem + 0.0862vw, 0.9375rem)",
      md: "clamp(1.125rem, 1.0905rem + 0.1724vw, 1.25rem)",
      lg: "clamp(1.6875rem, 1.6358rem + 0.2586vw, 1.875rem)",
      full: "9999px",
    },
    spacing: {
      "3xs": "5px",
      "2xs": "clamp(0.5625rem, 0.5453rem + 0.0862vw, 0.625rem)",
      xs: "clamp(0.875rem, 0.8578rem + 0.0862vw, 0.9375rem)",
      sm: "clamp(1.125rem, 1.0905rem + 0.1724vw, 1.25rem)",
      md: "clamp(1.6875rem, 1.6358rem + 0.2586vw, 1.875rem)",
      base: "clamp(1.6875rem, 1.6358rem + 0.2586vw, 1.875rem)",
      lg: "clamp(2.25rem, 2.181rem + 0.3448vw, 2.5rem)",
      xl: "clamp(3.375rem, 3.2716rem + 0.5172vw, 3.75rem)",
      "2xl": "clamp(4.5rem, 4.3621rem + 0.6897vw, 5rem)",
      "3xl": "clamp(6.75rem, 6.5431rem + 1.0345vw, 7.5rem)",
      "4xl": "clamp(9rem, 8.7241rem + 1.3793vw, 10rem)",

      /* One-up pairs */
      "3xs-2xs": "clamp(0.3125rem, 0.2263rem + 0.431vw, 0.625rem)",
      "2xs-xs": "clamp(0.5625rem, 0.4591rem + 0.5172vw, 0.9375rem)",
      "xs-sm": "clamp(0.875rem, 0.7716rem + 0.5172vw, 1.25rem)",
      "sm-md": "clamp(1.125rem, 0.9181rem + 1.0345vw, 1.875rem)",
      "md-lg": "clamp(1.6875rem, 1.4634rem + 1.1207vw, 2.5rem)",
      "lg-xl": "clamp(2.25rem, 1.8362rem + 2.069vw, 3.75rem)",
      "xl-2xl": "clamp(3.375rem, 2.9267rem + 2.2414vw, 5rem)",
      "2xl-3xl": "clamp(4.5rem, 3.6724rem + 4.1379vw, 7.5rem)",
      "3xl-4xl": "clamp(6.75rem, 5.8534rem + 4.4828vw, 10rem)",
    },
    colors: {
      /* PRIMITIVES */
      sky: {
        100: "#E3EAF0",
        200: "#C1D3F1",
        300: "#849FE5",
        accent: "#9281FF",
      },
      satellite: {
        100: "#120C56",
        200: "#0E0341",
        300: "#09022B",
        accent: "#4730DB",
      },
      sand: {
        100: "#FAECDA",
        200: "#E3B668",
        300: "#B58752",
        accent: "#FF7900",
      },
      soil: {
        100: "#65213A",
        200: "#4E1A31",
        300: "#2C0919",
        accent: "#B52C00",
      },
      shades: {
        black: {
          100: "rgba(0,0,0,0.05)",
          200: "rgba(0,0,0,0.10)",
          300: "rgba(0,0,0,0.20)",
          400: "rgba(0,0,0,0.30)",
          black: "rgba(0,0,0,1.00)",
        },
        white: {
          100: "rgba(255, 255, 255, 0.05)",
          200: "rgba(255, 255, 255, 0.10)",
          300: "rgba(255, 255, 255, 0.20)",
          400: "rgba(255, 255, 255, 0.30)",
        },
      },
      others: {
        white: "#fff",
        black: "#000",
      },
      status: {
        green: {
          light: "#58CA81",
          dark: "#15803D",
        },
        amber: {
          light: "#FBBF24",
          dark: "#D97706",
        },
        red: {
          light: "#F87171",
          dark: "#B91C1C",
        },
      },
      /* CUSTOM PRIMITIVES */
      custom: {
        lilac: "#9281FF",
        beam: "#4730DB",
        cornflower: "#EAC380",
      },
      /* INHERITED THEME COLORS */
      "surface/100": "var(--theme-surface-100)",
      "surface/200": "var(--theme-surface-200)",
      "surface/300": "var(--theme-surface-300)",
      "surface/accent": "var(--theme-surface-accent)",
      "surface/contrast": "var(--theme-surface-contrast)",
      content: {
        heading: "var(--theme-text-heading)",
        text: "var(--theme-text-default-primary)",
        "text/s": "var(--theme-text-default-secondary)",
        link: "var(--theme-text-interactive-text-link)",
      },
      primaryButton: {
        text: "var(--theme-text-interactive-button-primary)",
        bg: "var(--theme-button-primary-default)",
        hover: "var(--theme-button-primary-hover)",
        pressed: "var(--theme-button-primary-pressed)",
        focus: "var(--theme-button-primary-focus)",
      },
      secondaryButton: {
        text: "var(--theme-text-interactive-button-secondary)",
        bg: "var(--theme-button-secondary-default)",
        hover: "var(--theme-button-secondary-hover)",
        pressed: "var(--theme-button-secondary-pressed)",
        focus: "var(--theme-button-secondary-focus)",
        highlight: "var(--theme-ui-secondary-highlight)",
      },
      button: {
        disable: {
          DEFAULT: "var(--theme-button-disable-background)",
          text: "var(--theme-button-disable-text)",
        },
      },
      primaryUI: {
        bg: "var(--theme-ui-primary-default)",
        hover: "var(--theme-ui-primary-hover)",
        pressed: "var(--theme-ui-primary-pressed)",
        focus: "var(--theme-ui-primary-focus)",
      },
      secondaryUI: {
        bg: "var(--theme-ui-secondary-default)",
        hover: "var(--theme-ui-secondary-hover)",
        pressed: "var(--theme-ui-secondary-pressed)",
        focus: "var(--theme-ui-secondary-focus)",
        highlight: "var(--theme-ui-secondary-highlight)",
        contrast: "var(--theme-ui-secondary-contrast)",
      },
      ui: {
        disable: {
          DEFAULT: "var(--theme-ui-disable-background)",
          text: "var(--theme-ui-disable-text)",
        },
      },
      "border/100": "var(--theme-border-100)",
      "border/200": "var(--theme-border-200)",
      "border/300": "var(--theme-border-300)",
      "border/400": "var(--theme-border-400)",
      "border/500": "var(--theme-border-500)",
      "decor/100": "var(--theme-decorative-pattern-100)",
      "decor/200": "var(--theme-decorative-pattern-200)",
      "decor/300": "var(--theme-decorative-pattern-300)",
      "decor/400": "var(--theme-decorative-pattern-400)",
      "decor/accent": "var(--theme-decorative-pattern-accent)",
      "decor/white": "var(--theme-decorative-pattern-white)",
      "system/success": "var(--theme-system-success)",
      "system/warning": "var(--theme-system-warning)",
      "system/error": "var(--theme-system-error)",
    },
    transitionTimingFunction: {
      DEFAULT: "ease",
    },
    transitionDuration: {
      DEFAULT: "250ms",
      short: "100ms",
      long: "500ms",
      "extra-long": "750ms",
    },
    animation: {
      fadeIn: "fadeIn .25s ease",
      fadeOut: "fadeOut .25s ease",
      bounce: "bounce 0.7s infinite",
    },
    keyframes: {
      fadeIn: {
        from: { opacity: 0 },
      },
      fadeOut: {
        to: { opacity: 0 },
      },
      bounce: {
        "0%, 100%": {
          transform: "translateY(-50%)",
          animationTimingFunction: "cubic-bezier(0.8, 0, 1, 1)",
        },
        "50%": {
          transform: "translateY(0)",
          animationTimingFunction: "cubic-bezier(0, 0, 0.2, 1)",
        },
      },
    },
    extend: {
      maxWidth: {
        "title/xs": "18ch",
        "title/sm": "25ch",
        title: "30ch",
        leading: "45ch",
        sub: "45ch",
        text: "60ch",
        "text/sm": "50ch",
        body: "70ch",
      },
      transitionProperty: {
        space: "margin, padding, gap",
        bg: "background-color",
        border: "border-width, border-color",
        text: "color, font-width, font-size",
      },
    },
  },
  plugins: [
    require("@tailwindcss/container-queries"),
    sectionPlugin,
    circleIconPlugin,
    scrollbarHiddenPlugin,
    typographyPlugin,
    screenSafePlugin,
  ],
};
