import { BoundingBoxLines } from "./BoundingBoxLines";
import { Buildings } from "./Buildings";
import { CO2 } from "./CO2";
import { CO2ArrowDown } from "./CO2ArrowDown";
import { CO2ArrowUp } from "./CO2ArrowUp";
import { CO2CaretDown } from "./CO2CaretDown";
import { CO2CaretUp } from "./CO2CaretUp";
import { CO2Certificate } from "./CO2Certificate";
import { CO2Circle } from "./CO2Circle";
import { CloudCO2 } from "./CloudCO2";
import { Crop } from "./Crop";
import { Farmer } from "./Farmer";
import { FieldGrid } from "./FieldGrid";
import { FileCO2 } from "./FileCO2";
import { PlantGrow } from "./PlantGrow";
import { Polygon } from "./Polygon";
import { RecyclePlant } from "./RecyclePlant";
import { Satellite } from "./Satellite";
import { SatelliteRegular } from "./SatelliteRegular";
import { SelectionArrow } from "./SelectionArrow";
import { SoilMound } from "./SoilMound";
import { StackLines } from "./StackLines";
import { StackPlant } from "./StackPlant";
import { Tillage } from "./Tillage";
import { TillageSlash } from "./TillageSlash";
import { Tractor } from "./Tractor";

export const customIcons = {
  BoundingBoxLines,
  Buildings,
  CloudCO2,
  CO2,
  CO2ArrowDown,
  CO2ArrowUp,
  CO2CaretDown,
  CO2CaretUp,
  CO2Certificate,
  CO2Circle,
  Crop,
  Farmer,
  FieldGrid,
  FileCO2,
  PlantGrow,
  Polygon,
  RecyclePlant,
  Satellite,
  SelectionArrow,
  SoilMound,
  StackLines,
  StackPlant,
  Tillage,
  TillageSlash,
  Tractor,
  SatelliteRegular,
};
