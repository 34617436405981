import type { ComponentProps } from "react";

export function SatelliteRegular({
  ...props
}: Readonly<ComponentProps<"svg">>) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={32}
      height={32}
      fill="none"
      {...props}
    >
      <title>Satellite Regular</title>
      <g
        fill="currentColor"
        fillRule="evenodd"
        clipPath="url(#a)"
        clipRule="evenodd"
      >
        <path d="M16.871 5.74 19.7 8.568l2.828-2.828L19.7 2.91 16.87 5.74Zm-2.12-.707a1 1 0 0 0 0 1.414l4.242 4.242a1 1 0 0 0 1.414 0l4.243-4.242a1 1 0 0 0 0-1.414L20.407.79a1 1 0 0 0-1.414 0L14.75 5.033ZM2.729 19.882l2.828 2.828 2.829-2.828-2.829-2.829-2.828 2.829Zm-2.122-.707a1 1 0 0 0 0 1.414l4.243 4.243a1 1 0 0 0 1.414 0l4.243-4.243a1 1 0 0 0 0-1.414l-4.243-4.243a1 1 0 0 0-1.414 0L.607 19.175ZM8.14 9.679l5.385 5.385 1.357-1.357-5.385-5.385L8.14 9.68Zm-2.121-.707a1 1 0 0 0 0 1.414l6.799 6.8a1 1 0 0 0 1.414 0l2.77-2.772a1 1 0 0 0 0-1.414l-6.798-6.8a1 1 0 0 0-1.414 0L6.019 8.973Z" />
        <path d="M22.58 13.275a7 7 0 0 1 1.62 1.207l-9.9 9.9a7 7 0 0 1 8.28-11.107Zm-7.984 7.983a4.999 4.999 0 0 1 6.48-6.48l-6.48 6.48Z" />
        <path d="m14.65 21.117 6.467-6.466.127 4.538a2 2 0 0 1-2.055 2.054l-4.538-.126ZM11.921 13.518a1 1 0 0 1 0 1.414L9.093 17.76a1 1 0 0 1-1.414-1.414l2.828-2.828a1 1 0 0 1 1.414 0ZM17.578 7.86a1 1 0 0 1 0 1.415l-2.828 2.829a1 1 0 1 1-1.414-1.415l2.828-2.828a1 1 0 0 1 1.414 0ZM16.728 25.728a1 1 0 0 1 1-1 7 7 0 0 0 7-7 1 1 0 1 1 2 0 9 9 0 0 1-9 9 1 1 0 0 1-1-1Z" />
        <path d="M17.543 29.725a1 1 0 0 1 1-1 10 10 0 0 0 10-10 1 1 0 1 1 2 0 12 12 0 0 1-12 12 1 1 0 0 1-1-1Z" />
      </g>
    </svg>
  );
}
